import React, { Component } from 'react'
import { Layout, Title, Icon } from '../components'
import website from '../../config/website'
import theme from '../styles/theme'

const albums = [
  {
    band: 'Methyl Ethel',
    title: 'Oh Inhuman Spectacle',
    year: '2015',
    cover: require('../../content/albums/methyl-ethel-on-inhuman-spectacle.jpg'),
    link: 'https://pitchfork.com/reviews/albums/21691-oh-inhuman-spectacle/',
    spotify: 'https://open.spotify.com/embed/album/1WoHzxcLgGkIX6KdPm8k8R'
  },
  {
    band: 'Elder',
    title: 'Reflections of a Floating World',
    year: 'June 2, 2017',
    cover: require('../../content/albums/elder-reflections-of-a-floating-world.jpg'),
    spotify: 'https://open.spotify.com/embed/album/2sjt7XGkvXJGGvKYu0zm1y'
  },
  {
    band: 'Kelsey Lu',
    title: 'Church',
    year: '2016',
    cover: require('../../content/albums/kelsey-lu-church.jpg'),
    link: 'https://pitchfork.com/reviews/albums/22097-church-ep/',
    spotify: 'https://open.spotify.com/embed/album/74QfVSImAQZ1amvPlZtfOB'
  },
  {
    band: 'Alabama Shakes',
    title: 'Sound & Color',
    year: 'April 21, 2015',
    cover: require('../../content/albums/alabama-shakes-sound-and-color.png'),
    link: 'https://pitchfork.com/reviews/albums/20420-sound-color/',
    spotify: 'https://open.spotify.com/embed/album/03nQNGFi3dIxg6ghNbtVWW'
  },
  {
    band: 'Warpaint',
    title: 'Heads Up',
    year: '2016',
    cover: require('../../content/albums/warpaint-heads-up.jpg'),
    link: 'https://pitchfork.com/reviews/albums/22426-heads-up/',
    spotify: 'https://open.spotify.com/embed/album/5uGGOeDoB2jHetZJTbkwcC'
  },
  {
    band: 'Radiohead',
    title: 'Hail to the Thief',
    year: '9 June, 2013',
    cover: require('../../content/albums/radiohead-httt.jpg'),
    spotify: 'https://open.spotify.com/embed/album/1oW3v5Har9mvXnGk0x4fHm'
  },
  {
    band: 'The Rural Alberta Advantage',
    title: 'Hometowns',
    year: 'July 7, 2009',
    cover: require('../../content/albums/the-rural-alberta-advantage-hometowns.jpeg'),
    link: 'https://pitchfork.com/reviews/albums/12739-hometowns/',
    spotify: 'https://open.spotify.com/embed/album/1usy1Tge6LA3oQMXBO5pbM'
  },
  {
    band: 'Kashmir',
    title: 'No Balance Palace',
    year: 'October 10, 2005',
    cover: require('../../content/albums/kashmir-no-balance-palace.jpg'),
    spotify: 'https://open.spotify.com/embed/album/6xqwdmhfPQcyNODmCIWg55'
  },
  {
    band: 'Jeff Buckley',
    title: 'Grace',
    year: 'August 23, 1994',
    cover: require('../../content/albums/jeff-buckley-grace.jpg'),
    link: 'https://pitchfork.com/reviews/albums/995-grace-legacy-edition/',
    spotify: 'https://open.spotify.com/embed/album/07Fr36M0hRPJrSJMFWGnvD'
  }
]

// Active Child - You Are All I See
// Wild Beasts
// Zomby - With Love
// Honeyblood
// Antlers
// The Temper Trap

export default class Albums extends Component {
  constructor() {
    super()
    this.state = {
      playing: null
    }
    this.onResize = () => {
      this.setState({ rnd: Math.random() })
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  render() {
    return (
      <Layout logo>
        <header css={styles.header}>
          <Title css={styles.pageTitle}>Albums</Title>
          <p css={styles.pageSubtitle}>
            Some of my favorite music records. Maybe you already know these, or maybe you'll find something new or
            rediscover something forgotten.
          </p>
          <p>
            Some of these I don't listen to that often and there are other ones that I excluded despite of a high listen
            count. Some of these albums I come back to over and over again even after years. And some of them possess
            that really special sound.
          </p>
        </header>
        <main id={website.skipNavId} css={styles.main}>
          <div css={styles.list}>
            {albums.map((album, i) => (
              <div css={styles.album} key={i}>
                {this.state.playing && this.state.playing.spotify === album.spotify ? (
                  <div style={{ height: this.albumEl ? this.albumEl.offsetWidth : 0, background: '#333' }}>
                    <iframe
                      src={album.spotify}
                      title={album.title}
                      width='100%'
                      height='100%'
                      frameBorder='0'
                      allow='encrypted-media'
                    />
                  </div>
                ) : (
                  <div style={{ position: 'relative' }} css={styles.cov}>
                    <button
                      onClick={() => this.setState({ playing: album })}
                      css={{
                        zIndex: 10,
                        opacity: 0,
                        transition: 'opacity 0.3s',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        display: 'flex',
                        border: 'none',
                        width: '100%',
                        background: 'none',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        ':hover': {
                          opacity: 1
                        }
                      }}
                    >
                      <Icon icon='play' size={64} style={{ color: 'white' }} />
                    </button>
                    <img
                      css={styles.cover}
                      src={album.cover}
                      alt={`${album.band} ${album.title} cover`}
                      ref={el => {
                        this.albumEl = el
                      }}
                    />
                  </div>
                )}
                <h3 css={styles.band}>{album.band}</h3>
                <div css={styles.title}>{album.title}</div>
                <div css={styles.year}>{album.year}</div>
              </div>
            ))}
          </div>
        </main>
      </Layout>
    )
  }
}

const styles = {
  header: {
    maxWidth: theme.maxWidth + 160,
    margin: '0 auto',
    padding: '0 2rem',
    paddingTop: '2rem',
    p: {
      maxWidth: 640
    }
  },
  main: {
    maxWidth: theme.maxWidth + 360,
    margin: '0 auto',
    padding: '0 2rem',
    paddingTop: '2rem',
    marginTop: '4rem',
    paddingBottom: '4rem',
    [theme.media.desktop]: {
      paddingBottom: '8rem'
    }
  },
  pageTitle: {
    marginBottom: 30
  },
  pageSubtitle: {
    fontSize: 22,
    maxWidth: 640
  },
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '5rem',
    [theme.media.desktop]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: '5rem'
    }
  },
  album: {
    textAlign: 'center'
  },
  cover: {
    border: '1px solid #f2f2f2',
    transition: 'transform 0.3s'
  },
  cov: {
    ':hover': {
      img: {
        transform: 'scale(1.01)'
      }
    }
  },
  band: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 1.4,
    marginTop: 30,
    marginBottom: 10,
    padding: '0 8px'
  },
  title: {
    fontStyle: 'italic',
    fontSize: 17,
    marginBottom: 16
  },
  year: {
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'block',
    marginBottom: 0,
    opacity: 0.7,
    fontFamily: theme.fonts.mono,
    fontSize: 12,
    textTransform: 'uppercase'
  },
  link: {
    fontSize: 20,
    fontWeight: 500,
    color: 'black',
    textDecoration: 'none'
  }
}
